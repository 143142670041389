<template>
    <div class="page-team" :class="'skin'+ cur">
        <van-nav-bar
                :border="false" :placeholder="true" :fixed="true"
                :title="$t('会员规则')"
                left-arrow
                @click-left="onClickLeft"
        >
        </van-nav-bar>
        <div class="team-top">
            <div class="team-level">
                <div class="team-flex flex-center">
                    <div class="t-avatar">
                        <img class="ro-logo" src="../../assets/pictures/default-avatar.jpg">
                    </div>
                    <div class="flex_bd">
                        <div class="t-name">{{user.username}}</div>
                        <div class="t-info">{{$t('当前经验值')}}：{{user.exp}}</div>
                    </div>
                </div>
                <div class="team-banner">
                    <img src="../../assets/pictures/V1.png" v-if="cur == 0" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V2.png" v-if="cur == 1" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V3.png" v-if="cur == 2" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V4.png" v-if="cur == 3" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V5.png" v-if="cur == 4" class="vip-ico" alt="">
                    <img src="../../assets/pictures/V6.png" v-if="cur == 5" class="vip-ico" alt="">
                    <div class="text-label">
                        {{$t('您已达成该等级')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="team-wrapper">
            <ul class="lv-tabs">
                <li v-for="(item,index) in agent_list" :key="index" :class="cur + 1 > index ?'active':''">
                    <div class="lv-tab-item">
<!--                            <div class="lv">V{{item.level}}</div>-->
                        <div class="item">{{item.level}}</div>
                        <div class="dot"></div>
                        <div class="item">{{parseInt(item.exp)}}</div>
<!--                                <div class="text">{{$t(item.name)}}</div>-->
                    </div>
                </li>
            </ul>
            <div class="t-header">
                <div class="tt-box">
                    <span class="tt">{{$t('会员规则')}}</span>
                </div>
            </div>
            <div class="bs-panel">
                <div class="bs-panel_bd" style="padding: 10px 16px;">
                    <div class="m-g-cell">
                        <div class="g-label text-gray">{{$t('等级权益：')}}</div>
                        <div class="g-bd">
                            {{$t('等级权益内容')}}
                        </div>
                    </div>
                    <div class="m-g-cell">
                        <div class="g-label text-gray">{{$t('等级晋升规则：')}}</div>
                        <div class="g-bd">
                            {{$t('等级晋升规则内容')}}
                        </div>
                    </div>

                    <div class="m-g-cell">
                        <div class="g-label text-gray">{{$t('会员明细如下：')}}</div>
                        <table class="rule-table">
                            <tr>
                                <td style="width: 30%;">{{$t('会员等级')}}</td>
                                <td>{{$t('累计积分')}}</td>
                                <td style="width: 35%;">{{$t('购买机器人折扣')}}</td>
                            </tr>
                            <tr v-for="(item,index) in agent_list" :key="index">
                                <td>V.{{item.level}}</td>
                                <td>{{parseInt(item.exp)}}</td>
                                <td>
                                    <span v-if="index == 0">90%</span>
                                    <span v-else-if="index == 1">85%</span>
                                    <span v-else-if="index == 2">80%</span>
                                    <span v-else-if="index == 3">75%</span>
                                    <span v-else-if="index == 4">70%</span>
                                    <span v-else-if="index == 5">60%</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Foot :active="2"/>
    </div>
</template>

<script>
    import Foot from "@/components/Foot.vue";

    export default {
        components: {
            Foot
        },
        data() {
            return {
                show: false,
                name: "",
                color: ["#a77f41", "#dc3336", "#0b265c", "#065029", "#241384"],
                user: false,
                cur:0,
                list: [],
                agent_list: [],
                child_list: [],
                agent: {},
                exp_progress: 0,
                count: [0, 0, 0],
                stat: {
                    today: 0,
                    week: 0,
                    total: 0,
                },
                team: {
                    level: "T1",
                    pass: 0,
                    money: 0.0,
                },
                swiperOption: {
                    observer: true,
                    observeParents: true,
                    slidesPerView: 'auto',
                    spaceBetween: 15,
                    centeredSlidesBounds: true,
                },
            };
        },
        methods: {
            toslide(index){
                this.cur = index;
            },
            initSwipe(){
                let index = 0;
                if(this.user.agent){
                    index = this.user.agent.level - 2;
                }
                if(index >= 5){
                    index = 5;
                }
                return index;
            },
            getExpRate(u, a) {
                if (u > a) {
                    return 100;
                }
                return parseFloat(u / a * 100).toFixed(2);
            },
            childs() {
                // let level = this.user && this.user.agent && typeof this.user.agent.level !== 'undefined' ? this.user.agent.level : 0;
                // return this.child_list.filter(function (item) {
                //     return item.level <= level;
                // });
                return this.child_list;
            },
            viewAgent(item) {
                this.agent = item
                this.exp_progress = (this.user.exp / this.agent.exp * 100).toFixed(0);
                if (this.exp_progress > 100) {
                    this.exp_progress = 100;
                }

                if (this.exp_progress < 0) {
                    this.exp_progress = 0;
                }

            },
            agents() {
                return this.agent_list.filter(function (item) {
                    return item.level > 1;
                });
            },
            onClickLeft() {
                this.$router.go(-1);
            },
            getData() {
                let url = this.$api_url.distributions;
                this.$axios
                    .get(url)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.count = res.data.data.count;
                            this.list = res.data.data.list;
                            this.agent_list = res.data.data.agent_list;
                            this.child_list = res.data.data.child_list;
                            if (this.agent_list.length > 1) {
                                this.viewAgent(this.agent_list[1])
                            }
                            this.stat = res.data.data.stat;
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(err.message);
                    });
            },
        },
        mounted() {
            this.getData();
            this.getUser().then((res) => {
                this.user = res.data;
                let cc =  this.user.agent.level - 1;
                if(cc < 0){
                    cc = 0;
                }
                if(cc > 5){
                    cc = 5;
                }
                this.cur = cc;
                console.log(this.user);
            });
        },
    };
</script>
<style scoped>
    .m-g-cell{
        font-size: 15px;
        padding: 10px 0;

    }
    .m-g-cell .g-label{
        margin-bottom: 10px;
    }







</style>